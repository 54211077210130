import { createAction, props } from '@ngrx/store';
import {
  UserModel,
  UserPlansSummaryFilters,
  UserPlansSummaryModel,
} from '@domain/models/user.model';
import { PaginatedData } from '@base/wrappers/paginated-data';

export class UserActions {
  static readonly loadUser = createAction(
    '[User] Load User',
    props<{idUser:string }>()
  );

  static readonly loadUserSuccess = createAction(
    '[User] Load User Success',
    props<{
      user:UserModel;
      loading: false;
    }>()
  );

  static readonly loadUserFailure = createAction(
    '[User] Load Users Failure',
    props<{ error: any, loading:false }>()
  );


}
