import { UseCase } from '@domain/base/use-case';
import { UserRepository } from '@domain/repositories/user.repository';
import { Observable } from 'rxjs';


export class PostChangePasswordUseCase
  implements UseCase<{ idUser:string,currentPassword:string,newPassword:string }, boolean>
{
  constructor(private userRepository: UserRepository) {}

  execute(params: { idUser:string,currentPassword:string,newPassword:string}): Observable<boolean> {
    return this.userRepository.postChangePassword(params.idUser,params.currentPassword,params.newPassword);
  }
}
